export const monokai = {
  base: 'vs-dark',
  inherit: true,
  rules: [
    {
      foreground: '75715e',
      token: 'comment',
    },
    {
      foreground: 'e6db74',
      token: 'string',
    },
    {
      foreground: 'ae81ff',
      token: 'constant.numeric',
    },
    {
      foreground: 'FF80F4',
      token: 'attribute.value.number.css',
    },
    {
      foreground: 'ae81ff',
      token: 'constant.language',
    },
    {
      foreground: 'ae81ff',
      token: 'constant.character',
    },
    {
      foreground: 'ae81ff',
      token: 'constant.other',
    },
    {
      foreground: 'f92672',
      token: 'keyword',
    },
    {
      foreground: 'f92672',
      token: 'variable.other.dollar.only.js',
    },
    {
      foreground: 'f92672',
      token: 'storage',
    },
    {
      foreground: '66d9ef',
      fontStyle: 'italic',
      token: 'storage.type',
    },
    {
      foreground: 'a6e22e',
      fontStyle: 'underline',
      token: 'entity.name.class',
    },
    {
      foreground: 'a6e22e',
      fontStyle: 'italic underline',
      token: 'entity.other.inherited-class',
    },
    {
      foreground: 'a6e22e',
      token: 'entity.name.function',
    },
    {
      foreground: 'fd971f',
      fontStyle: 'italic',
      token: 'variable.parameter',
    },
    {
      foreground: 'F92672',
      token: 'entity.name.tag',
    },
    {
      foreground: 'F92672',
      token: 'tag.html',
    },
    { foreground: 'A6E22E', token: 'attribute.name.html' }, //green
    {
      foreground: 'a6e22e',
      token: 'entity.other.attribute-name',
    },
    {
      foreground: '66d9ef',
      token: 'support.function',
    },
    {
      foreground: '66d9ef',
      token: 'support.constant',
    },
    {
      foreground: '66d9ef',
      fontStyle: 'italic',
      token: 'support.type',
    },
    {
      foreground: '66d9ef',
      fontStyle: 'italic',
      token: 'support.class',
    },
    {
      foreground: 'f8f8f0',
      background: 'f92672',
      token: 'invalid',
    },
    {
      foreground: 'f8f8f0',
      background: 'ae81ff',
      token: 'invalid.deprecated',
    },
    {
      foreground: 'cfcfc2',
      token: 'meta.structure.dictionary.json string.quoted.double.json',
    },
    {
      foreground: '75715e',
      token: 'meta.diff',
    },
    {
      foreground: '75715e',
      token: 'meta.diff.header',
    },
    {
      foreground: 'f92672',
      token: 'markup.deleted',
    },
    {
      foreground: 'a6e22e',
      token: 'markup.inserted',
    },
    {
      foreground: 'e6db74',
      token: 'markup.changed',
    },
    {
      foreground: 'ae81ffa0',
      token: 'constant.numeric.line-number.find-in-files - match',
    },
    {
      foreground: 'e6db74',
      token: 'entity.name.filename.find-in-files',
    },
    {
      token: 'attribute.value.html',
      foreground: 'FFEE99',
    },
    {
      token: 'tag.css',
      foreground: 'A6E22E',
    },
    {
      token: 'attribute.name.css',
      foreground: '66D9EF',
    },
    {
      token: 'metatag.html',
      foreground: 'f92672', // red
    },
    {
      token: 'metatag.content.html',
      foreground: 'A6E22E', //green
    },
    {
      token: 'delimiter.html',
      foreground: 'F8F8F2', //white
    },

    {
      token: 'attribute.value.css',
      foreground: 'FFEE99', //white
    },
    // attribute.value.css  string
    {
      token: 'attribute.value.number.css',
      foreground: 'FF80F4', //white
    },
    // attribute.value.number.css number
    {
      token: 'attribute.value.unit.css',
      foreground: 'F92672', //white
    },
    {
      foreground: 'F8F8F2', //white
    },
    // metatag.html - doctype red
    //metatag.content.html html tag green
    // text color F8F8F2
  ],
  colors: {
    // 'foreground': '#F8F8F2',
    'editor.foreground': '#F8F8F2',
    'editor.background': '#222222',
    'editor.selectionBackground': '#9D550F',
    'editor.inactiveSelectionBackground': '#bbbbbb',
    'editor.lineHighlightBackground': '#3E3D32',
    'editorCursor.foreground': '#F8F8F0',
    'editorWhitespace.foreground': '#3B3A32',
    'editorIndentGuide.activeBackground': '#9D550FB0',
  },
};
