import { useState, useEffect, useRef } from 'react';
import Modal from './modal';
import { useAuth } from '../../../lib/auth';
import { ButtonLink, P } from '../../../lib/ui';

function PublicEditorPromptModal({ toggle, isOpen }) {
  const { user, reload } = useAuth();
  useEffect(() => {
    const reloadIfNeeded = async () => {
      if (isOpen && !user) {
        await reload();
      }
    };
  
    reloadIfNeeded();
  }, [user, isOpen]);
  return (
    <Modal toggle={toggle} isOpen={isOpen} size="sm:max-w-lg" closeButton>
      <div className="text-left">
        <div className="sm:flex sm:items-start mb-4">
          <h3
            className="text-3xl font-semibold text-gray-900"
            id="modal-headline">
            👋 Welcome to Postdrop
          </h3>
        </div>
        <div>
          <P>
            To save templates or send test email you need to have an account so
            we can verify your email.
          </P>
          <P>Sign up or log in to try out all the features.</P>
        </div>
        <div className="mt-6 mb-1 sm:flex">
          <ButtonLink href="/signup" target="_blank">
            Signup
          </ButtonLink>
          <ButtonLink outline className="ml-2" href="/login" target="_blank">
            Login
          </ButtonLink>
        </div>
      </div>
    </Modal>
  );
}

export default PublicEditorPromptModal;
