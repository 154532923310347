import React, { useEffect } from "react";

import Modal from "./modal";
import { Listbox } from "@headlessui/react"
import arrowDownIcon from '/images/arrowdown.png'
import { ErrorBanner } from "../../ui"

const FONT_SIZE_OPTIONS = [
  { label: "12", value: 12 },
  { label: "14", value: 14 },
  { label: "16", value: 16 },
  { label: "18", value: 18 },
]

const INDENT_OPTIONS = [
  { label: "Spaces", value: "SPACES" },
  { label: "Tabs", value: "TABS" },
]

const SPACE_SIZE_OPTIONS = [
  { label: "2", value: 2 },
  { label: "4", value: 4 },
]

function EditorSettingModal({ formError, intialValues, toggle, isOpen, handleChangeInput, htmlEditorRef, cssEditorRef }) {
  useEffect(() => {
    if (htmlEditorRef.current && cssEditorRef.current) {
      htmlEditorRef.current.getModel().updateOptions({
        indentSize: intialValues?.indent_size ? intialValues?.indent_size : 2
      })
      cssEditorRef.current.getModel().updateOptions({
        indentSize: intialValues?.indent_size ? intialValues?.indent_size : 2
      })
    }
  }, [intialValues])

  return (
    <Modal toggle={toggle} isOpen={isOpen} size="sm:max-w-sm" closeButton>
      <div className="text-left">
        <div className="sm:flex sm:items-start ">
          <h3
            className="text-3xl font-medium text-gray-900"
            id="modal-headline"
          >
            Editor Settings
          </h3>
        </div>
        <div>
          <div className="mt-2 sm:flex sm:items-start">
            <div className="mt-5 sm:mt-0 flex-shrink-0 flex items-center">
              <div className="text-left">
                <div className="py-4 w-[330px] flex flex-col gap-2">
                  <div className="flex items-center gap-4 w-full">
                    <label className="relative inline-block w-[50px] h-[24px]">
                      <input
                        type="checkbox"
                        className="h-0 w-0 opacity-0"
                        checked={intialValues?.word_wrap || false}
                        onChange={()=>{
                          handleChangeInput("word_wrap", !intialValues?.word_wrap);
                        }}
                        id="word_wrap"
                      />
                      <button onClick={(e) => {
                        handleChangeInput("word_wrap", !intialValues?.word_wrap);
                      }} className="slider round shadow cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">

                      </button>
                    </label>
                    <label className="cursor-pointer select-none" htmlFor="word_wrap">Word Wrap</label>
                  </div>
                  <div className="flex items-center gap-4 w-full ">
                    <label className="relative inline-block w-[50px] h-[24px]">
                      <input
                        checked={intialValues?.line_numbers || false}
                        id="line_numbers"
                        type="checkbox"
                        onChange={()=>{
                          handleChangeInput("line_numbers", !intialValues?.line_numbers);
                        }}
                        className="h-0 w-0 opacity-0"
                      />
                      <button onClick={(e) => {
                        handleChangeInput("line_numbers", !intialValues?.line_numbers);
                      }} className="slider round shadow cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">

                      </button>
                    </label>
                    <label className="cursor-pointer select-none" htmlFor="line_numbers">Line Numbers</label>
                  </div>
                  <div className="flex items-center gap-4 w-full">
                    <label className="relative inline-block w-[50px] h-[24px]">
                      <input
                        type="checkbox"
                        className="h-0 w-0 opacity-0"
                        checked={intialValues?.auto_complete_html || false}
                        onChange={()=>{
                          handleChangeInput("auto_complete_html", !intialValues?.auto_complete_html);
                        }}
                        id="auto_complete_html"
                      />
                      <button onClick={(e) => {
                        handleChangeInput("auto_complete_html", !intialValues?.auto_complete_html);
                      }} className="slider round shadow cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">

                      </button>
                    </label>
                    <label className="cursor-pointer select-none" htmlFor="auto_complete_html">Auto-Complete HTML</label>
                  </div>
                  <div className="flex items-center gap-4 w-full ">
                    <label className="relative inline-block w-[50px] h-[24px]">
                      <input
                        type="checkbox"
                        className="h-0 w-0 opacity-0"
                        checked={intialValues?.format_on_save || false}
                        onChange={()=>{
                          handleChangeInput("format_on_save", !intialValues?.format_on_save);
                        }}
                        id="format_on_save"
                      />
                      <button onClick={(e) => {
                        handleChangeInput("format_on_save", !intialValues?.format_on_save);
                      }} className="slider round shadow cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">

                      </button>
                    </label>
                    <label className="cursor-pointer select-none" htmlFor="format_on_save">Format on Save</label>
                  </div>
                  <div className="flex items-center gap-4 w-full ">
                    <label className="relative inline-block w-[50px] h-[24px]  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
                      <input
                        className="h-0 w-0 opacity-0"
                        type="checkbox"
                        checked={intialValues?.auto_save || false}
                        onChange={()=>{
                          handleChangeInput("auto_save", !intialValues?.auto_save);
                        }}
                        id="auto_save"
                      />
                      <button onClick={(e) => {
                        handleChangeInput("auto_save", !intialValues?.auto_save);
                      }} className="slider round shadow cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
                      </button>
                    </label>
                    <label className="cursor-pointer select-none" htmlFor="auto_save">Auto-Save</label>
                  </div>
                  <div className="flex items-center gap-4 w-full">

                    <div className="editor-settings-dropdown">
                      <label className="cursor-pointer select-none" htmlFor="font_size">Font Size</label>
                      <Listbox
                        value={intialValues?.font_size}
                        onChange={(val) => {
                          handleChangeInput(
                            "font_size",
                            Number(val)
                          );
                        }}
                      >
                        <Listbox.Button id="font_size" className="border border-[#f4f4f4] rounded text-[#46567d] w-full flex items-center justify-between py-[0.6rem] px-4">{intialValues?.font_size} <img height={'10px'} width={"10px"} src={arrowDownIcon} /></Listbox.Button>
                        <Listbox.Options className="w-full overflow-hidden absolute right-0 w-32 mt-2  border border-gray-200 rounded-md absolute shadow-lg bg-white outline-none mt-0.5 text-xs">
                          {FONT_SIZE_OPTIONS.map((item) =>
                          (<Listbox.Option className={`${item?.value === intialValues?.font_size ? "bg-blue-500 text-white hover:bg-blue-500 hover:text-white" : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"} py-[0.6rem] px-4 cursor-pointer font-medium `} key={item.value} value={item.value}>
                            {item.label}
                          </Listbox.Option>)
                          )}
                        </Listbox.Options>
                      </Listbox>
                    </div>


                    <div className="editor-settings-dropdown">
                      <label className="cursor-pointer select-none" htmlFor="indent">Indent</label>

                      <Listbox
                        value={intialValues?.indent}
                        onChange={(val) => {
                          handleChangeInput(
                            "indent",
                            val
                          );
                        }}
                      >
                        <Listbox.Button id="indent" className="border border-[#f4f4f4] rounded text-[#46567d] w-full flex items-center justify-between py-[0.6rem] px-4">{intialValues?.indent === "SPACES" ? "Spaces" : "Tabs"} <img height={'10px'} width={"10px"} src={arrowDownIcon} /></Listbox.Button>
                        <Listbox.Options className="w-full overflow-hidden absolute right-0 w-32 mt-2  border border-gray-200 rounded-md absolute shadow-lg bg-white outline-none mt-0.5 text-xs">
                          {INDENT_OPTIONS.map((item) => (
                            <Listbox.Option className={`${item?.value === intialValues?.indent ? "bg-blue-500 text-white hover:bg-blue-500 hover:text-white" : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"} py-[0.6rem] px-4 cursor-pointer font-medium`} key={item.value} value={item.value}>
                              {item.label}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Listbox>
                    </div>

                    <div className="editor-settings-dropdown">
                      <label className="cursor-pointer select-none" htmlFor="indent_size">Indent Size</label>

                      <Listbox
                        value={intialValues?.indent_size}
                        onChange={(val) => {
                          handleChangeInput(
                            "indent_size",
                            Number(val)
                          );
                        }}
                      >
                        <Listbox.Button id="indent_size" className="border border-[#f4f4f4] rounded text-[#46567d] w-full flex items-center justify-between py-[0.6rem] px-4">{intialValues?.indent_size} <img height={'10px'} width={"10px"} src={arrowDownIcon} /></Listbox.Button>
                        <Listbox.Options className="w-full overflow-hidden absolute right-0 w-32 mt-2  border border-gray-200 rounded-md absolute shadow-lg bg-white outline-none mt-0.5 text-xs">
                          {SPACE_SIZE_OPTIONS.map((item) => (
                            <Listbox.Option className={`${item?.value === intialValues?.indent_size ? "bg-blue-500 text-white hover:bg-blue-500 hover:text-white" : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"} py-[0.6rem] px-4 cursor-pointer font-medium`} key={item.value} value={item.value}>
                              {item.label}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Listbox>
                    </div>
                  </div>

                  <div className="flex flex-col items-start w-full">
                    {formError && <ErrorBanner title={formError} />}

                    <button
                      onClick={() => {
                        toggle();
                      }}
                      className="bg-[#5395F1] text-white border-none flex items-center justify-center rounded-lg py-[0.6rem] px-[1.8rem]"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditorSettingModal;
