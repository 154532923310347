
import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import DownloadTemplate from "../modals/download-inlined-template";
import ShareTemplate from "../modals/share-template";
import SendPreview from "../modals/send-preview";
import PublicEditorPromptModal from "../modals/public-editor-prompt";
import toast from "react-hot-toast";
import EditorSettingModal from "../modals/editor-setting-modal"

const UPDATE = gql`
  mutation UpdateTemplate($id: ID!, $css: String, $rawTemplate: String) {
    updateTemplate(input: { id: $id, css: $css, rawTemplate: $rawTemplate }) {
      id
      name
      css
      rawTemplate
      updatedAt
    }
  }
`;

interface IProps {
  hasChanges?: boolean;
  data?: { id: string; css: string; rawTemplate: string };
  buttonRef?: any;
  cssEditorRef?: any;
  htmlEditorRef?: any;
  formatOnSave: boolean
}

export const Save = ({
  hasChanges = false,
  data,
  formatOnSave,
  cssEditorRef,
  htmlEditorRef,
  buttonRef
}: IProps) => {
  const [isModalOpen, toggleModal] = useState(false);
  const [formError, setFormError] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [updateTemplate] = useMutation(UPDATE);
  const [codeFormatted, setCodeFormatted] = useState<boolean>(false)

  useEffect(() => {
    if (codeFormatted && data && formatOnSave) {
      saveTemplate()
      setCodeFormatted(false)
    }
  }, [formatOnSave, codeFormatted])

  const isPublic = data?.id === "starter";
  const saveTemplate = async () => {
    if (isPublic) {
      return toggleModal(true);
    }
    setFormError(null);
    setSubmitting(true);
    try {
      const promise = updateTemplate({
        variables: {
          id: data?.id,
          css: data?.css,
          rawTemplate: data?.rawTemplate,
        },
      });
      toast.promise(promise, {
        loading: "Saving...",
        success: "Saved!",
        error: "Error saving template.",
      });
      await promise;
    } catch (error) {
      setFormError(error.message || "Unable to save template.");
    }
    setSubmitting(false);
  };
  return (
    <>
      {hasChanges ? (
        <button
          tabIndex={0}
          disabled={isSubmitting}
          className="font-medium font-sm inline-flex items-center text-pink hover:text-pink-dark focus:outline-none animate-pulse"
          onClick={() => {
            if (formatOnSave) {
              htmlEditorRef.current.getAction('editor.action.formatDocument').run();
              cssEditorRef.current.getAction('editor.action.formatDocument').run();
              setTimeout(() => {
                setCodeFormatted(true)
              }, 2000)
            } else {
              saveTemplate()
            }
          }}
          ref={buttonRef}
        >
          <svg
            className="mr-2"
            width="16"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 16"
            stroke="none"
          >
            <path d="M16 7.442a3.878 3.878 0 0 0-1.181-2.778 3.807 3.807 0 0 0-2.298-1.056.166.166 0 0 1-.128-.08 5.122 5.122 0 0 0-9.4 1.529.167.167 0 0 1-.151.133A3.06 3.06 0 0 0 0 8.24a2.883 2.883 0 0 0 .824 2.14 3.679 3.679 0 0 0 2.403.92.666.666 0 1 0-.02-1.333 2.192 2.192 0 0 1-1.448-.533 1.583 1.583 0 0 1-.426-1.194 1.717 1.717 0 0 1 1.32-1.678c.249-.061.51-.065.761-.013a.667.667 0 0 0 .8-.613 3.79 3.79 0 0 1 7.213-1.392.641.641 0 0 0 .628.38 2.467 2.467 0 0 1 1.838.7 2.5 2.5 0 0 1 .774 1.818 2.556 2.556 0 0 1-2.292 2.525.666.666 0 1 0 .175 1.322A3.872 3.872 0 0 0 16 7.442Z" />
            <path d="M8.471 6.493a.667.667 0 0 0-.942 0L5.195 8.826a.667.667 0 0 0 .472 1.14h1.166a.166.166 0 0 1 .167.167v4.834a1 1 0 0 0 2 0v-4.834a.166.166 0 0 1 .167-.166h1.166a.667.667 0 0 0 .472-1.138L8.47 6.493Z" />
          </svg>{" "}
          {isSubmitting ? "Saving..." : "Save"}
        </button>
      ) : (
        <button
          className="inline-flex items-center text-green-400 focus:outline-none"
          disabled
        >
          <svg
            className="mr-2"
            width="16"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 16"
            stroke="none"
          >
            <path d="M16 7.909a3.843 3.843 0 0 0-3.477-3.968.167.167 0 0 1-.128-.08 5.123 5.123 0 0 0-9.4 1.529.167.167 0 0 1-.151.133A3.095 3.095 0 0 0 .416 7.016 2.98 2.98 0 0 0 0 8.649a2.862 2.862 0 0 0 .823 2.066c.337.323.743.565 1.187.708a.643.643 0 0 0 .829-.573v-.017a.656.656 0 0 0-.439-.69 1.72 1.72 0 0 1-.64-.38 1.582 1.582 0 0 1-.427-1.19 1.725 1.725 0 0 1 2.081-1.69.66.66 0 0 0 .73-.352.667.667 0 0 0 .072-.29c.057-3.824 5.529-4.914 7.212-1.362a.65.65 0 0 0 .627.38 2.489 2.489 0 0 1 2 .872c.44.522.655 1.195.6 1.875a2.284 2.284 0 0 1-.982 1.778.65.65 0 0 0-.266.643l.006.039a.646.646 0 0 0 1.017.405A3.6 3.6 0 0 0 16 7.91Z" /><path d="M8.154 6.967a4.333 4.333 0 1 0 0 8.666 4.333 4.333 0 0 0 0-8.666Zm2.083 2.77a.5.5 0 0 1 .1.7L8.4 13.017a.996.996 0 0 1-.733.4h-.074a.99.99 0 0 1-.704-.296l-1-1a.501.501 0 0 1 .711-.706l.867.867a.166.166 0 0 0 .25-.018l1.82-2.43a.5.5 0 0 1 .7-.1v.003Z" />
          </svg>{" "}
          Saved
        </button>
      )}
      {formError && (
        <p className="text-pink items-center inline-flex">Error: {formError}</p>
      )}
      {isPublic && (
        <PublicEditorPromptModal
          isOpen={isModalOpen}
          toggle={() => toggleModal(false)}
        />
      )}
    </>
  );
};

export const Download = ({
  data,
}: {
  hasChanges?: boolean;
  data?: { css: string; rawTemplate: string; name: string };
}) => {
  const [isModalOpen, toggleModal] = useState(false);
  const handleClick = () => toggleModal(true);

  return (
    <>
      <button
        className="inline-flex text-blue-light hover:text-white focus:outline-none whitespace-nowrap items-center"
        tabIndex={0}
        onClick={handleClick}
        id="btn-downloadexport"
      >
        <svg
          className="mr-1"
          width="16"
          height="16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.086.379a1.293 1.293 0 012.207.914v6.044a.167.167 0 00.167.166h1.772a.646.646 0 01.489 1.071l-3.233 3.717a.646.646 0 01-.976 0L4.28 8.574a.647.647 0 01.488-1.07h1.773a.167.167 0 00.167-.167V1.293c0-.343.136-.672.378-.914zm7.454 11.15a.808.808 0 01.882-.177.81.81 0 01.501.748v1.237a2.667 2.667 0 01-2.667 2.666H2.744a2.666 2.666 0 01-2.663-2.666V12.1a.808.808 0 111.616 0v1.237a1.048 1.048 0 001.047 1.046h10.512a1.048 1.048 0 001.048-1.046V12.1c0-.214.085-.42.236-.571z"
            />
          </g>
        </svg>{" "}
        <span className="hidden sm:block">Download/Export</span>
      </button>
      <DownloadTemplate
        isOpen={isModalOpen}
        toggle={() => toggleModal(false)}
        data={data}
      />
    </>
  );
};

export const EditorSettingOptions = ({ formError, intialValues, setSettingData, submitData, htmlEditorRef, cssEditorRef }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const handleChangeInput = (key: string, val: string | number | boolean) => {
    submitData({
      ...intialValues,
      [key]: val
    })
    setSettingData({
      ...intialValues,
      [key]: val
    })
  }

  const handleClick = () => { !formError && toggleModal((prev) => !prev) }

  return (
    <>
      <button
        className="inline-flex text-blue-light hover:text-white focus:outline-none whitespace-nowrap items-center"
        tabIndex={0}
        id="btn-editorsettings"
        onClick={handleClick}
      >
        <svg className="mr-1" width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.305 0h13.39C15.415 0 16 .585 16 1.305v13.391A1.306 1.306 0 0 1 14.695 16H1.305C.585 16 0 15.416 0 14.696V1.306C0 .584.584 0 1.305 0Zm.362 14.694 12.666-.027c.184 0 .334-.15.334-.334V1.64a.333.333 0 0 0-.334-.333L1.64 1.333a.333.333 0 0 0-.334.334l.028 12.694c0 .184.15.333.334.333Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.43 11.667H3a.667.667 0 0 1 0-1.334h4.43a2.333 2.333 0 0 1 4.473 0H13a.667.667 0 0 1 0 1.334h-1.097a2.333 2.333 0 0 1-4.472 0ZM10.668 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0ZM3.764 5.667H3a.667.667 0 1 1 0-1.334h.764a2.333 2.333 0 0 1 4.472 0H13a.667.667 0 1 1 0 1.334H8.236a2.333 2.333 0 0 1-4.472 0ZM7 5a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" />
          </g>
        </svg>
        <span className="hidden sm:block">Editor Settings</span>
      </button>
      <EditorSettingModal formError={formError} intialValues={intialValues} htmlEditorRef={htmlEditorRef} cssEditorRef={cssEditorRef} toggle={handleClick} isOpen={isModalOpen} handleChangeInput={handleChangeInput} />
    </>
  );
};

export const Share = ({ data }: { data?: { id: string } }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const isPublic = data?.id === "starter";
  const handleClick = () => {
    return toggleModal(true);
  };

  return (
    <>
      <button
        className="inline-flex text-blue-light hover:text-white focus:outline-none whitespace-nowrap items-center"
        tabIndex={0}
        onClick={handleClick}
        id="btn-share"
      >
        <svg
          className="mr-1"
          width="16"
          height="16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.745.175a.333.333 0 01.17.102l3 3.334a.333.333 0 010 .446l-3 3.333a.333.333 0 01-.581-.223V6a.167.167 0 00-.167-.167h-3.66a2.77 2.77 0 00-2.626 1.926.333.333 0 01-.6.082 3.825 3.825 0 01-.614-2.094 3.921 3.921 0 013.92-3.914h3.58a.166.166 0 00.167-.166V.499a.333.333 0 01.411-.324zM10.05 6.881a.167.167 0 01.118-.048h1a.166.166 0 01.166.167v7.498A1.333 1.333 0 0110 15.832H1.333A1.333 1.333 0 010 14.498V5.832a1.333 1.333 0 011.333-1.333H3.62a.167.167 0 01.163.203 4.62 4.62 0 00-.115.965.167.167 0 01-.165.166H1.333v8.666H10V7c0-.044.018-.086.049-.118z"
            />
          </g>
        </svg>{" "}
        <span className="hidden sm:block">Share</span>
      </button>
      {isPublic ? (
        <PublicEditorPromptModal
          isOpen={isModalOpen}
          toggle={() => toggleModal(false)}
        />
      ) : (
        <ShareTemplate
          isOpen={isModalOpen}
          toggle={() => toggleModal(false)}
          id={data?.id}
        />
      )}
    </>
  );
};

export const Send = ({
  data,
}: {
  hasChanges?: boolean;
  data?: { id: string; rawTemplate: string; css: string };
}) => {
  const [isModalOpen, toggleModal] = useState(false);
  const isPublic = data?.id === "starter";
  const handleClick = () => {
    return toggleModal(true);
  };

  return (
    <>
      <button
        className="inline-flex items-center whitespace-nowrap bg-blue border border-transparent rounded-md shadow-sm inline-flex justify-center font-medium text-white py-1 px-2 hover:bg-blue-dark focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue"
        tabIndex={0}
        onClick={handleClick}
        id="btn-sendtest"
      >
        <svg
          className="mr-1 fill-current text-white"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0234 0.0833202C15.2574 -0.0468849 15.5432 -0.0207295 15.7517 0.149987C15.959 0.319497 16.0472 0.602683 15.9751 0.866654L12.5805 13.3593C12.5013 13.6477 12.3065 13.8865 12.0461 14.0147C11.7839 14.1438 11.4813 14.1504 11.2141 14.0327L9.19367 13.14C9.13102 13.112 9.05822 13.1286 9.01255 13.1813C8.62215 13.632 6.86151 15.6607 6.83655 15.684C6.62223 15.885 6.34475 15.9974 6.05639 16C5.56803 16.0031 5.12983 15.6881 4.95943 15.2113L3.35943 10.714C3.34122 10.6616 3.3319 10.6063 3.33191 10.5507L1.24935 9.63065C0.894204 9.4774 0.656318 9.12387 0.639174 8.72383C0.622029 8.3238 0.828726 7.94959 1.16935 7.76399L15.0234 0.0833202ZM10.5792 5.32199L4.37635 10.6973L5.66915 14.3253L6.07427 11.692C6.08703 11.606 6.12173 11.5252 6.17475 11.458L10.8071 5.55465C10.8366 5.52511 10.8522 5.48362 10.8499 5.44107C10.8475 5.39852 10.8274 5.35916 10.7949 5.33332C10.7691 5.29984 10.7307 5.27946 10.6895 5.2773C10.6483 5.27513 10.6082 5.29139 10.5792 5.32199Z"
          />
        </svg>{" "}
        Send Test
      </button>
      {isPublic ? (
        <PublicEditorPromptModal
          isOpen={isModalOpen}
          toggle={() => toggleModal(false)}
        />
      ) : (
        <SendPreview
          isOpen={isModalOpen}
          toggle={() => toggleModal(false)}
          data={data}
        />
      )}
    </>
  );
};
