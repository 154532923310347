import { useForm } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { find, filter } from 'lodash';
import { useAuth } from '../../auth';
import storage from '../../../lib/storage';
import { Listbox, Transition } from '@headlessui/react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import Modal from './modal';
import { ErrorBanner, Button } from '../../../lib/ui';
import toast from 'react-hot-toast';

const isServer = typeof window === 'undefined';
const SEND_PREVIEW = gql`
  mutation SendPreview(
    $id: ID!
    $rawTemplate: String!
    $css: String
    $subject: String!
    $email: String!
    $recipientId: String!
  ) {
    sendPreviewTemplate(
      input: {
        id: $id
        rawTemplate: $rawTemplate
        css: $css
        subject: $subject
        email: $email
        recipientId: $recipientId
      }
    )
  }
`;

const subjOpts = {
  required: 'An email subject is required.',
};

type Inputs = {
  subject: string;
};

const defaultValues = {
  subject: 'Test HTML Email',
};

const LIST_RECIPIENTS = gql`
  query ListRecipients($accountId: ID) {
    listRecipients(accountId: $accountId) {
      data {
        id
        email
        status
        verifiedAt
        createdAt
        updatedAt
      }
    }
  }
`;

const upgradeButton = (
  <p className="text-sm my-4">
    <a
      href="/plans"
      target="_blank"
      className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded">
      Upgrade
    </a>
  </p>
);

const RecipientDropdown = ({ items, isOpen, onChange, loading = false }) => {
  const [selectedItem, setItem] = useState(null);
  const { user } = useAuth();
  const wrappedSetItem = (val) => {
    storage.set('postdrop.lastUsed.recipient', val);
    setItem(val);
    onChange(val);
  };
  useEffect(() => {
    // if selectedItem is not in
    if (!isServer && !loading && isOpen && !selectedItem && items.length > 0) {
      const email = user?.attributes?.email;
      const lastUsedRecipient = storage.get('postdrop.lastUsed.recipient');
      if (
        lastUsedRecipient &&
        items.some((i) => i?.email === lastUsedRecipient)
      ) {
        wrappedSetItem(lastUsedRecipient);
      } else if (email && items.some((i) => i?.email === email)) {
        wrappedSetItem(email);
      } else {
        wrappedSetItem(items[0]?.email);
      }
    }
  }, [loading, wrappedSetItem, items, user, isOpen, selectedItem]);
  return (
    <Listbox
      as="div"
      className="space-y-1"
      disabled={loading}
      value={selectedItem}
      onChange={wrappedSetItem}>
      {({ open }) => (
        <>
          <div className="relative">
            <span className="inline-block w-full rounded-md shadow-sm">
              <Listbox.Button className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                <span className="block truncate">
                  {loading ? 'loading...' : selectedItem || 'Select item...'}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="currentColor">
                    <path
                      d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Listbox.Button>
            </span>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
              <Listbox.Options
                static
                className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                {!loading &&
                  items.map((recipient) => (
                    <Listbox.Option key={recipient.id} value={recipient.email}>
                      {({ selected, active }) => (
                        <div
                          className={`${
                            active ? 'text-white bg-blue' : 'text-gray-900'
                          } cursor-default select-none relative py-2 pl-8 pr-4`}>
                          <span
                            className={`${
                              selected ? 'font-semibold' : 'font-normal'
                            } block truncate`}>
                            {recipient.email}
                          </span>
                          {selected && (
                            <span
                              className={`${
                                active ? 'text-white' : 'text-blue'
                              } absolute inset-y-0 left-0 flex items-center pl-1.5`}>
                              <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

function SendPreviewModal({ toggle, isOpen, data }) {
  const [formError, setFormError] = useState(null);
  const [dropdownItem, setDropdownItem] = useState(null);
  const {
    register,
    handleSubmit,
    errors,
    reset,
    // setValue,
    // getValues,
    formState: { isSubmitting },
  } = useForm<Inputs>({ defaultValues });
  const [sendPreviewTemplate] = useMutation(SEND_PREVIEW);
  const [
    listRecipients,
    { loading, data: result, startPolling, stopPolling },
  ] = useLazyQuery(LIST_RECIPIENTS, {
    ssr: false,
    fetchPolicy: 'cache-and-network',
  });
  const items = result?.listRecipients?.data || [];
  const filteredItems = filter(items, { status: 'VERIFIED' });
  const isLoading = isServer || (loading && !data?.listRecipients?.data);

  const onSubmit = async ({ subject }, e) => {
    e.preventDefault();
    setFormError(null);
    try {
      const recipientId = find(filteredItems, { email: dropdownItem })?.id;
      const promise = sendPreviewTemplate({
        variables: {
          id: data?.id,
          email: dropdownItem,
          subject,
          css: data?.css,
          rawTemplate: data?.rawTemplate,
          recipientId,
        },
      });
      toast.promise(promise, {
        loading: 'Sending test email...',
        success: `Test email sent to ${dropdownItem}`,
        error: 'Error sending test email.',
      });
      await promise;
      toggle();
      reset();
    } catch (error) {
      setFormError(error.message || 'An unknown error occurred on login.');
    }
  };
  useEffect(() => {
    // fetch initial data
    if (isOpen && !stopPolling && !startPolling) {
      listRecipients();
    }
    // poll after initial fetch
    if (isOpen && stopPolling && startPolling) {
      startPolling(25 * 1000);
      return () => stopPolling();
    }
  }, [isOpen, startPolling, stopPolling, listRecipients]);
  const showUpgrade = formError?.includes('please upgrade');
  return (
    <Modal toggle={toggle} isOpen={isOpen} size="sm:max-w-lg" closeButton>
      <form onSubmit={handleSubmit(onSubmit)} className="text-left">
        <div className="sm:flex sm:items-start mb-4">
          <h3
            className="text-3xl font-medium text-gray-900"
            id="modal-headline">
            Send Test Email
          </h3>
        </div>
        <div className="mt-4">
          <label
            htmlFor="recipient"
            className="block text-sm font-medium text-gray-700">
            Select Test Recipient
          </label>
          <div className="mt-1">
            <RecipientDropdown
              loading={isLoading}
              items={filteredItems}
              isOpen={isOpen}
              onChange={(val) => {
                setDropdownItem(val);
              }}
            />
            <p className="text-xs text-gray-700 mt-1">
              Add a{' '}
              <Link
                href="/recipients"
                className="font-medium text-blue hover:text-blue"
                target="_blank">
                
                  test recipient
                
              </Link>
              .
            </p>
          </div>
          {/* {errors?.email && <ErrorBanner title={errors.email.message} />} */}
        </div>
        <div className="mt-4">
          <label
            htmlFor="subject"
            className="block text-sm font-medium text-gray-700">
            Email Subject
          </label>
          <div className="mt-1">
            <input
              name="subject"
              id="subject"
              ref={register(subjOpts)}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
            />
          </div>
          {errors?.subject && <ErrorBanner title={errors.subject.message} />}
        </div>

        {formError && <ErrorBanner title={formError} children={showUpgrade ? upgradeButton : undefined} />}
        <div className="mt-6 mb-1 sm:flex">
          <Button tag="button" type="submit" disabled={isSubmitting} id="btn-sendtest-modal">
            {isSubmitting ? 'Sending...' : 'Send'}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default SendPreviewModal;
