import React, {
  useState,
  useRef,
} from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import classnames from "classnames";
import { gql, useQuery } from "@apollo/client";
import {
  ResizeContent,
  ResizeHandleRight,
  ResizePanel,
} from "react-hook-resize-panel";

import { Menu, Transition } from "@headlessui/react";
import NewTemplateModal from "../modals/new-template";
import { useAuth } from "../../auth";
import { Spinner } from "../../ui";
import EditTemplateModal from "../modals/edit-template";
import DeleteTemplateModal from "../modals/delete-template";
import DuplicateTemplateModal from "../modals/duplicate-template";
import ShareTemplateModal from "../modals/share-template";
import { Plans } from "../forms/account-plan";

const activeLinkClasses =
  "active bg-[#5395F166] text-white [&>.email]:!fill-white";
const linkClasses =
  "text-blue-200 hover:bg-[#5395F166] [&>.email]:fill-blue-200 hover:text-white group-hover:text-white";
const defaultClasses =
  "cursor-pointer group rounded-md py-[4px] flex items-center text-md font-medium";


const ACCOUNT_PLAN_QUERY = gql`
    query AccountPlan($accountId: ID) {
      getAccount {
        id
        name
        plan
        hasBilling
        sendCount
        totalTeamMembers
      }
      listRecipients(accountId: $accountId) {
        data {
          id
          email
          status
          verifiedAt
          createdAt
          updatedAt
        }
      }
      listTemplates(accountId: $accountId) {
        data {
          name
          id
          updatedAt
          shareId
        }
      }
    }
  `;

const LIST_TEMPLATES_QUERY = gql`
    query ListTemplates($accountId: ID) {
      listTemplates(accountId: $accountId) {
        data {
          name
          id
          updatedAt
          shareId
        }
      }
    }
  `;

const Actions = ({
  edit,
  del,
  share,
  duplicate,
  className,
}: {
  edit: (any) => void;
  del: (any) => void;
  share: (any) => void;
  duplicate: (any) => void;
  className?: string
}) => {
  const menu = useRef<HTMLInputElement>();
  return (
    <div className={`relative inline-block dots text-left opacity-0 ${className || ''}`}>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              onClick={(e) => {
                e.stopPropagation();
              }}
              ref={menu}
              className={`bg-transparent transition-all duration-500 rounded-[50%] text-white`}
            >
              <svg
                width="12"
                className="dots"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C11.9983 4.40923 11.3656 2.88411 10.2407 1.75926C9.1159 0.634414 7.59077 0.00172054 6 0ZM4.25 6C4.25 6.19778 4.19135 6.39112 4.08147 6.55557C3.97159 6.72002 3.81541 6.84819 3.63269 6.92388C3.44996 6.99957 3.24889 7.01937 3.05491 6.98079C2.86093 6.9422 2.68275 6.84696 2.5429 6.70711C2.40304 6.56725 2.3078 6.38907 2.26922 6.19509C2.23063 6.00111 2.25044 5.80004 2.32612 5.61732C2.40181 5.43459 2.52998 5.27841 2.69443 5.16853C2.85888 5.05865 3.05222 5 3.25 5C3.51522 5 3.76957 5.10536 3.95711 5.29289C4.14465 5.48043 4.25 5.73478 4.25 6ZM6 5C6.19778 5 6.39112 5.05865 6.55557 5.16853C6.72002 5.27841 6.8482 5.43459 6.92388 5.61732C6.99957 5.80004 7.01937 6.00111 6.98079 6.19509C6.9422 6.38907 6.84696 6.56725 6.70711 6.70711C6.56726 6.84696 6.38907 6.9422 6.19509 6.98079C6.00111 7.01937 5.80005 6.99957 5.61732 6.92388C5.43459 6.84819 5.27841 6.72002 5.16853 6.55557C5.05865 6.39112 5 6.19778 5 6C5 5.73478 5.10536 5.48043 5.2929 5.29289C5.48043 5.10536 5.73479 5 6 5ZM8.75 7C8.55222 7 8.35888 6.94135 8.19443 6.83147C8.02998 6.72159 7.90181 6.56541 7.82612 6.38268C7.75044 6.19996 7.73063 5.99889 7.76922 5.80491C7.8078 5.61093 7.90304 5.43275 8.0429 5.29289C8.18275 5.15304 8.36093 5.0578 8.55491 5.01921C8.74889 4.98063 8.94996 5.00043 9.13269 5.07612C9.31541 5.15181 9.47159 5.27998 9.58147 5.44443C9.69135 5.60888 9.75 5.80222 9.75 6C9.75 6.26522 9.64465 6.51957 9.45711 6.70711C9.26957 6.89464 9.01522 7 8.75 7Z"
                  fill="white"
                />
              </svg>
            </Menu.Button>

            <Transition
              show={open}
              enter="transition ease-in duration-75"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-out duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 w-32 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none z-10"
              >
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={edit}
                        className={`${active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-xs font-medium leading-5 text-left`}
                      >
                        Edit Name
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={share}
                        className={`${active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-xs font-medium leading-5 text-left`}
                      >
                        Share Preview
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={duplicate}
                        className={`${active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-xs font-medium leading-5 text-left`}
                      >
                        Duplicate
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={del}
                        className={`${active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 !text-[#D51507] text-xs font-medium leading-5 text-left`}
                      >
                        Delete
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

const TemplateSidebar = ({ activeITem }) => {
  const defaultTableModalsState = {
    isEditModalOpen: false,
    currentItem: null,
    isDeleteModalOpen: false,
    isShareModalOpen: false,
    isDuplicateModalOpen: false,
  };
  const [modalState, toggleModalState] = useState(defaultTableModalsState);
  const editItem = (item) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleModalState({
      ...defaultTableModalsState,
      currentItem: item,
      isEditModalOpen: true,
    });
  };
  const deleteItem = (item) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleModalState({
      ...defaultTableModalsState,
      currentItem: item,
      isDeleteModalOpen: true,
    });
  };
  const shareItem = (item) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleModalState({
      ...defaultTableModalsState,
      currentItem: item,
      isShareModalOpen: true,
    });
  };

  const duplicate = (item) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleModalState({
      ...defaultTableModalsState,
      currentItem: item,
      isDuplicateModalOpen: true,
    });
  };

  const closeModal = () =>
    toggleModalState((prev) => ({
      ...defaultTableModalsState,
      currentItem: prev.currentItem,
    }));

  const [isNTModalOpen, toggleNTModal] = useState(false);
  const { user } = useAuth();

  const { loading: planLoading, data: planData } = useQuery(ACCOUNT_PLAN_QUERY, {
    ssr: false,
    skip: !user,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const { loading, data } = useQuery(LIST_TEMPLATES_QUERY, {
    ssr: false,
    skip: !user,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const account = planData?.getAccount || {};
  const plan = account?.plan || "FREE";

  const isLoading = !data?.listTemplates?.data && (!user || loading);
  const router = useRouter();

  const sortData = (array) => {
    if (array?.length > 0) {
      return [...array].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      return [];
    }
  };

  return (
    <div className="min-h-[calc(100vh-3.03rem)] flex border-r">
      <ResizePanel initialWidth={300} minWidth={150}>
        <ResizeContent className="bg-postdrop-dark h-full w-full overflow-x-scroll hide-scrollbar">
          <div className="sidebar min-w-[150px] w-full h-full sidebar-desktop resize-x flex flex-shrink-0 bg-postdrop-dark">
            <div className=" w-full h-full flex flex-col justify-between items-center">
              <div className="w-full h-0 overflow-x-scroll hide-scrollbar border-gray-200 pt-[.5rem] pb-4 flex flex-col flex-grow overflow-y-auto">
                <div className="flex-grow flex flex-col">
                  <nav className="w-full px-2 pe-[2px] space-y-1 flex flex-col gap-[5px] ">
                    <div
                      onClick={() => toggleNTModal(true)}
                      className={classnames(
                        "flex gap-[4px] items-center justify-center active cursor-pointer !font-normal text-[14px] bg-blue hover:bg-blue-dark text-white",
                        defaultClasses
                      )}
                      id="new-template"
                    >
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.333332 8.50004C0.333332 10.0164 0.782974 11.4986 1.6254 12.7594C2.46782 14.0202 3.66519 15.0028 5.06609 15.5831C6.46699 16.1634 8.0085 16.3152 9.49569 16.0194C10.9829 15.7236 12.3489 14.9934 13.4212 13.9212C14.4934 12.849 15.2235 11.4829 15.5194 9.99573C15.8152 8.50855 15.6633 6.96703 15.0831 5.56613C14.5028 4.16523 13.5201 2.96786 12.2594 2.12544C10.9986 1.28302 9.51632 0.833374 8 0.833374C5.96738 0.835668 4.01866 1.64414 2.58138 3.08142C1.1441 4.5187 0.335626 6.46742 0.333332 8.50004ZM3.66667 8.16671C3.66667 7.9899 3.7369 7.82033 3.86193 7.6953C3.98695 7.57028 4.15652 7.50004 4.33333 7.50004H6.83333C6.87753 7.50004 6.91993 7.48248 6.95118 7.45123C6.98244 7.41997 7 7.37758 7 7.33337V4.83337C7 4.65656 7.07024 4.48699 7.19526 4.36197C7.32029 4.23695 7.48985 4.16671 7.66667 4.16671H8.33333C8.51014 4.16671 8.67971 4.23695 8.80474 4.36197C8.92976 4.48699 9 4.65656 9 4.83337V7.33337C9 7.37758 9.01756 7.41997 9.04881 7.45123C9.08007 7.48248 9.12246 7.50004 9.16667 7.50004H11.6667C11.8435 7.50004 12.013 7.57028 12.1381 7.6953C12.2631 7.82033 12.3333 7.9899 12.3333 8.16671V8.83337C12.3333 9.01019 12.2631 9.17975 12.1381 9.30478C12.013 9.4298 11.8435 9.50004 11.6667 9.50004H9.16667C9.12246 9.50004 9.08007 9.5176 9.04881 9.54886C9.01756 9.58011 9 9.62251 9 9.66671V12.1667C9 12.3435 8.92976 12.5131 8.80474 12.6381C8.67971 12.7631 8.51014 12.8334 8.33333 12.8334H7.66667C7.48985 12.8334 7.32029 12.7631 7.19526 12.6381C7.07024 12.5131 7 12.3435 7 12.1667V9.66671C7 9.62251 6.98244 9.58011 6.95118 9.54886C6.91993 9.5176 6.87753 9.50004 6.83333 9.50004H4.33333C4.15652 9.50004 3.98695 9.4298 3.86193 9.30478C3.7369 9.17975 3.66667 9.01019 3.66667 8.83337V8.16671Z"
                          fill="white"
                        />
                      </svg>
                      New Template
                    </div>
                    {(isLoading || planLoading) ? (
                      <Spinner className="h-12 w-12" />
                    ) : (
                      data?.listTemplates?.data?.length > 0 && (
                        <>
                          {sortData(data?.listTemplates?.data)?.map(
                            (template) => {
                              return (
                                <>
                                  <div
                                    title={template?.name}
                                    onClick={() => {
                                      router.push(`/templates/${template?.id}`);
                                    }}
                                    id={template?.id}
                                    className={classnames(
                                      "cursor-pointer flex items-center [&>.dots]:hover:opacity-100 hover:text-white [&>.email]:hover:fill-white justify-between group py-[5px] text-blue-100 px-[10px] rounded-md text-[12px] font-normal text-md ",
                                      activeITem == template?.id
                                        ? activeLinkClasses
                                        : linkClasses
                                    )}
                                  >
                                    <div className="flex items-center email justify-start gap-1 overflow-hidden">
                                      <div className="w-[12px] fill-inherit">
                                        <svg
                                          width="12"
                                          className="fill-inherit"
                                          height="12"
                                          viewBox="0 0 11 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M5.244 5.02802C5.21988 5.02802 5.19628 5.02107 5.176 5.008L0.128003 1.829C0.0859636 1.80253 0.0527627 1.76414 0.0326364 1.71872C0.0125102 1.67331 0.00637123 1.62292 0.0150028 1.574C0.056327 1.34287 0.177663 1.13365 0.357754 0.982993C0.537845 0.832338 0.765206 0.749857 1 0.75H9.25C9.47852 0.750414 9.70001 0.829083 9.87759 0.972911C10.0552 1.11674 10.1781 1.31705 10.226 1.5405C10.2363 1.58939 10.2317 1.64025 10.2128 1.6865C10.1939 1.73276 10.1616 1.77229 10.12 1.8L5.312 5.008C5.29173 5.02107 5.26812 5.02802 5.244 5.02802ZM5.2548 5.77667C5.42333 5.77454 5.58766 5.72378 5.72803 5.6305L9.86153 2.875C9.89918 2.84995 9.94291 2.83558 9.98808 2.83342C10.0333 2.83126 10.0782 2.84139 10.118 2.86273C10.1579 2.88408 10.1912 2.91583 10.2145 2.95462C10.2377 2.99341 10.25 3.03778 10.25 3.083V8.5C10.25 8.76522 10.1447 9.01957 9.95714 9.20711C9.7696 9.39464 9.51525 9.5 9.25003 9.5H1.00003C0.734814 9.5 0.48046 9.39464 0.292924 9.20711C0.105387 9.01957 3.05508e-05 8.76522 3.05508e-05 8.5V3.08C7.48382e-06 3.03526 0.0119897 2.99134 0.0347271 2.95281C0.0574646 2.91428 0.0901242 2.88256 0.129299 2.86096C0.168474 2.83935 0.212728 2.82866 0.257446 2.82998C0.302164 2.83131 0.345706 2.84461 0.383531 2.8685L4.77803 5.6425C4.92072 5.73221 5.08627 5.7788 5.2548 5.77667Z"
                                            fill="null"
                                          />
                                        </svg>
                                      </div>
                                      <div className="min-w-[70px] p-0 overflow-hidden relative inline text-start no-underline text-ellipsis whitespace-nowrap hide-scrollbar">
                                        {template?.name}
                                      </div>
                                    </div>
                                    <Actions
                                      edit={editItem(template)}
                                      del={deleteItem(template)}
                                      share={shareItem(template)}
                                      duplicate={duplicate(template)}
                                    />
                                  </div>
                                </>
                              );
                            }
                          )}
                        </>
                      )
                    )}
                  </nav>
                </div>
              </div>
              {!isLoading && !planLoading && data?.listTemplates?.data?.length > 0 &&
                <div className="flex text-[white] flex-col items-start text-[10px] gap-[8px] justify-start w-full p-4">
                  <p className="m-0 p-0">
                    <strong>{`${plan || "Free"} Plan`}</strong>{" "}
                    <Link target="_blank" href={"/account"}>(view usage)</Link>
                  </p>
                  {plan?.toLowerCase() === 'free' && <p className="m-0 p-0">
                    {`Limited to ${Plans[plan].templates} templates & ${Plans[plan].quota} tests/day`}
                  </p>}
                  {plan?.toLowerCase() === "free" && (
                    <Link
                      id="btn-upgrade-sidebar"
                      href="/plans"
                      target="_blank"
                      className={classnames(
                        "flex items-center w-full justify-center active cursor-pointer !font-normal text-[14px] hover:bg-[#D5075D] bg-[#EC0867] text-white",
                        defaultClasses
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                      >
                        <g clip-path="url(#a)">
                          <path
                            fill="#fff"
                            d="M13.63 6.18a.333.333 0 0 0-.297-.18H8.416L9.66.404a.333.333 0 0 0-.597-.267L2.395 9.471a.333.333 0 0 0 .272.529h4.917l-1.243 5.593a.333.333 0 0 0 .597.267l6.667-9.333a.333.333 0 0 0 .024-.347Z"
                          />
                        </g>
                        <defs>
                          <clipPath id="a">
                            <path fill="#fff" d="M0 0h16v16H0z" />
                          </clipPath>
                        </defs>
                      </svg>
                      Upgrade
                    </Link>
                  )}
                </div>
              }
            </div>
          </div>
        </ResizeContent>
        <ResizeHandleRight className="cursor-col-resize bg-postdrop-dark">
          <div className="cursor-grab w-2">
          </div>
        </ResizeHandleRight>
      </ResizePanel>

      <NewTemplateModal
        isOpen={isNTModalOpen}
        toggle={() => toggleNTModal(false)}
      />
      <EditTemplateModal
        item={modalState.currentItem}
        isOpen={modalState.isEditModalOpen}
        toggle={closeModal}
      />
      <DeleteTemplateModal
        item={modalState.currentItem}
        isOpen={modalState.isDeleteModalOpen}
        toggle={closeModal}
        route={modalState?.currentItem?.id === activeITem}
      />
      <ShareTemplateModal
        isOpen={modalState.isShareModalOpen}
        toggle={closeModal}
        id={modalState.currentItem?.id}
      />
      <DuplicateTemplateModal
        isOpen={modalState.isDuplicateModalOpen}
        toggle={closeModal}
        item={modalState.currentItem}
      />
    </div>
  );
};

export default TemplateSidebar;
