import { useState, useEffect } from 'react';
import Modal from './modal';
import { Button, P, Meta, ErrorBanner } from '../../../lib/ui';
import juice from 'juice';
import { ReadOnlyEditor } from '../editor';

const download = (data, filename) => {
  const file = new Blob([data], { type: 'text/html' });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, filename);
  } else {
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

function DownloadTemplateModal({ toggle, isOpen, data }) {
  const [content, setContent] = useState('');
  const [isCopied, setCopied] = useState(false);
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setCopied(false);
    try {
      const result = juice(data?.rawTemplate, { extraCss: data?.css || '' });
      setContent(result);
    } catch (_error) {}
  }, [isOpen, setContent]);
  const downloadAsFile = () => {
    const filename = data?.name || 'postdrop-template';
    // @ts-ignore
    download(content, `${filename}.html`);
  };
  const copyTemplate = () => {
    try {
      navigator.clipboard.writeText(content);
      setCopied(true);
    } catch (_error) {}
  };
  return (
    <Modal toggle={toggle} isOpen={isOpen} size="sm:max-w-4xl" closeButton>
      <div className="text-left">
        <div className="sm:flex sm:items-start mb-4">
          <h3
            className="text-3xl font-medium text-gray-900"
            id="modal-headline">
            Export Email Template
          </h3>
        </div>
        <p className="block text-sm font-medium text-gray-800 my-6">
          Inlined HTML/CSS
        </p>
        <ReadOnlyEditor value={content} height="500px" />
        <div className="mt-8 mb-1 sm:flex">
          <Button tag="button" type="button" onClick={downloadAsFile}>
            Download
          </Button>
          <Button
            onClick={copyTemplate}
            tag="button"
            type="button"
            outline
            className="ml-2">
            {isCopied ? 'Copied!' : 'Copy'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DownloadTemplateModal;
